.main-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 480px;
    height: auto;
    margin-left: -232px;
    margin-top: -100px;
    background-color: white;
    box-shadow: 1px 1px 8px 2px #555555;
}

.modal-blue-bar {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 24px;
    background-color: #6898f3;
}

.modal-content {
    width: 88%;
    padding: 12px 4px 20px 4px;
}

.modal-message {
    font-family: Sen, Calibri, sans-serif;
    text-align: center;
    padding: 8px;
    overflow-wrap: break-word;
}

.modal-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-button {
    width: 100px;
    border-radius: 8px;
    border: 1px solid #d3d3d3;
    margin: 8px;
    padding: 4px;
    box-shadow: 1px 1px 0 0 #d3d3d3;
    text-align: center;
}

.modal-button:hover {
    cursor: pointer;
    filter: brightness(0.9);
}

.modal-button:active {
    transform: scale(0.99);
}

.modal-button.positive {
    background-color: #68acf3;
    color: white;
}

.modal-button.negative:hover {
    background-color: #fefefe;
}

@media (max-width: 520px) {
    .modal-container {
        width: 90%;
        left: 5%;
        margin-left: 0px;
    }
}
