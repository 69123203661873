.Toastify__toast-container {
    width: 340px;
}

.Toastify__toast-body {
    margin: auto 4px;
}

.Toastify__toast {
    min-height: 48px;
}