@import url('https://fonts.googleapis.com/css?family=Sen:700');

html {
    box-sizing: border-box;
  }

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Sen', sans-serif;
    overflow-x: hidden;
}

#main-container {
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

button:hover {
    cursor: pointer;
}

button:active {
    transform: scale(0.98);
}

.empty-div {
    display: none;
    visibility: hidden;
}