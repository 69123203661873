#degree-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 12px;
}

.all-filters {
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
    font: bold 22px Sen, sans-serif;
}

.filter-container {
	margin: 0 4px;
    padding-left: 32px;
}

select {
	font: bold 18px Sen, sans-serif;
	width: auto;
}
  
select:focus {
	outline: none;
}

.filter {
	margin: 6px;
	padding: 4px;
	width: 250px;
}

#button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 80%;
    margin: 4px;
    padding: 4px;
}

#button-container button {
    width: 45%;
    padding: 4px;
    margin: 4px;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    background-color: #6898f3;
    font: normal 18px Sen, sans-serif;
    color: white;
    outline: none;
}

#button-container button.inactive {
    background-color: #6899f381;
}

#button-container button.inactive:hover {
    cursor: not-allowed;
}

.remove-filter-button {
    position: relative;
    top: 5px;
    opacity: 0.4;
    padding: 4px;
    font-size: 28px;
}

.remove-filter-button:hover {
    opacity: 0.8;
    color: rgb(255, 101, 101);
    cursor: pointer;
    transition: opacity ease 0.25s;
    transition: color ease 0.25s;
}

.hidden {
    visibility: hidden;
}

@media (max-width: 520px) {
    .all-filters {
        font: bold 18px Sen, sans-serif;
    }
    select {
        font: bold 16px Sen, sans-serif;
    }
    .filter {
        width: 220px;
    }
    .filter-container {
        padding-left: 0px;
    }
    #button-container button {
        font: normal 16px Sen, sans-serif;
    }
    .remove-filter-button {
        font-size: 26px;
    }
}