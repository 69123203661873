/* Normal List Styles */

.list-wrapper {
    position: relative;
    margin: 8px;
    width: 30%;
    text-align: center;
}

.minor-selected .list-wrapper {
    width: 22%;
}

#schedule-lists-container .list-wrapper {
    width: 45%;
}

.list-title {
    width: 100%;
    text-align: center;
    padding: 4px 0px;
    margin: 0px;
    font-size: 18px;
}

.search-with-list {
    width: 100%;
    background-color: rgb(233, 233, 233)
}

.list-search-bar {
    font-family: FontAwesome, Calibri, sans-serif;
    font-size: 16px;
    padding: 6px 8px;
    margin: 6px 4px 2px 4px;
    width: 94%;
    border-radius: 6px;
    border-width: 0px;
}

.list-search-bar:focus {
    outline: none !important;
    border: solid 1px #cccccc;
    padding: 5px 7px;
}

.add-bar-container {
    position: relative;
}

.add-class-bar {
    font-family: FontAwesome, Calibri, sans-serif;
    font-size: 16px;
    padding: 6px 8px;
    margin: 6px 4px 2px 4px;
    width: 94%;
    border-radius: 6px;
    border-width: 0px;
}

.add-class-bar:focus {
    outline: none !important;
    border: solid 1px #cccccc;
    padding: 5px 7px;
}

.add-class-bar:focus + .add-class-button {
    color: rgb(162, 224, 162);
}

.add-class-button {
    position: absolute;
    right: 12px;
    top: 6px;
    font-size: 18px;
    margin: 4px 4px;
    color: rgb(182, 182, 182);
}

.add-class-button:hover {
    color: rgb(162, 224, 162);
    filter: brightness(0.96);
    cursor: pointer;
}

.add-class-button:active {
    transform: scale(0.97);
}

.course-list {
    background-color: rgb(233, 233, 233);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 120px;
    margin-bottom: 4px;
    border-radius: 4px;
    border: solid 1px rgb(233, 233, 233);
    border-top-width: 3px;
    border-bottom-width: 5px;
    border-right-width: 0px;
    transition: background-color 0.12s ease-in-out, outline 0.06s ease, opacity 0.2s ease;
}

div::-webkit-scrollbar {
	width: 3px;
}

div::-webkit-scrollbar-track {
	border-radius: 100px;
}

div::-webkit-scrollbar-thumb {
	background: rgb(195, 195, 195);
	border-radius: 20px;
}

/* Dragging Modifiers */

.can-drop {
    outline-style: dashed;
    outline-color: #999999;
    outline-width: 2px;
    outline-offset: -4px;
}

.no-drop {
    opacity: 0.4;
}

.dragging-can-drop {
    background-color: rgb(211, 211, 211);
}

/* Clicking Modifiers */

.click-overlay {
    position: absolute;
    bottom: 4px;
    left: 0px;
    width: 100%;
    height: 118px;
    opacity: 0.4;
}

.can-click {
    border: dashed 2px black;
    border-radius: 4px;
}

.can-click:hover {
    background-color: rgb(192, 192, 192);
    cursor: pointer;
}

.no-click {
    background-color: rgb(247, 247, 247);
}

/* Media Modifiers */

@media (max-width: 1200px) {
    .list-search-bar {
        width: 94%;
    }
}

@media (max-width: 780px) {
    .minor-selected .list-wrapper {
        width: 46%;
    }
}

@media (max-width: 660px) {
    .list-wrapper {
        width: 44%;
    }
    .list-title {
        font-size: 16px;
    }
}

@media (max-width: 520px) {
    .minor-selected .list-wrapper {
        width: 44%;
    }
    .list-search-bar {
        font-size: 14px;
    }
    .add-class-bar {
        font-size: 14px;
    }
    .add-class-button {
        margin: 3px 0px;
        padding: 0px 4px;
        background-color: white;
    }
}
