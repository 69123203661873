/* Delete Properties */

.delete-container {
    position: fixed;
    width: 100%;
    height: 92px;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top, black, #00000038);
    z-index: 3;
    color: white;
    animation: slideup 0.4s;
}

.delete-drop {
    color: red;
    background-image: linear-gradient(to top, black, #0000006c);
}

.delete-clicked:hover {
    cursor: pointer;
    color: red;
    background-image: linear-gradient(to top, black, #0000006c);
}

.delete-course {
    font-size: 36px;
    padding: 4px;
}

.delete-text {
    font-size: 16px;
    padding: 4px;
}

@keyframes slideup {
    from { bottom: -92px; }
    to { bottom: 0; }
}
