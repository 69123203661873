#scheduler-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.main-container {
    position: relative;
}

.lists-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    width: 66vw;
    padding: 8px 12px 16px 12px;
    margin: 0px 16px 36px 16px;
    background-color: rgb(248, 248, 248);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 2px 8px 2px #ccc;
    overflow-y: scroll;
}

.year-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 44%;
    margin: 16px 8px;
    border: solid 1px #cccccc;
    border-radius: 4px;
}

.year-title {
    font-size: 18px;
    width: 100%;
    padding: 4px;
    background-color: #e2e2e2;
    color: #333333;
    border-bottom: solid 1px #bbbbbb;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.year-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin: 0 4px;
}

.lists-title {
    width: 66vw;
    margin: 0px 16px;
    margin-top: 20px;
    padding: 4px 0px;
    font-size: 22px;
    background-color: #6898f3;
    box-shadow: 0 2px 8px 2px #ccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: white;
    text-align: center;
}

.schedule-button {
    font-size: 18px;
    text-align: center;
    background-color: #6898f3;
    border-radius: 50%;
}

.schedule-button:hover {
    cursor: pointer;
    filter: brightness(0.92);
}

#delete-schedule {
    position: absolute;
    top: 22px;
    right: 112px;
    color: white;
    width: 28px;
    padding: 4px;
}

#copy-link-schedule {
    position: absolute;
    top: 22px;
    right: 72px;
    color: white;
    width: 28px;
    padding: 4px;
}

#menu-reference {
    position: absolute;
    top: 22px;
    right: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

#menu-button {
    position: relative;
    color: white;
    width: 28px;
    padding: 4px;
}

.menu-container {
    position: relative;
    width: auto;
    top: 3px;
    right: -16px;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 240px;
    height: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    z-index: 2;
    height: 0;
    display: none;
}

.menu-open {
    height: auto;
    display: flex; 
}

.menu-row {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    opacity: .7;
    transition: .1s ease;
    font-family: Sen,sans-serif;
    font-size: 16px;
    color: #000;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
}

.menu-row:hover {
    cursor: pointer;
    background-color: #dddddd;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
}

.menu-icon {
    padding: 4px 8px;
    font-size: 18px;
}

.menu-text {
    padding: 4px 8px;
}

#export-icon {
    font-size: 20px;
    padding: 4px 10px 4px 9px;
}

#import-icon {
    font-size: 20px;
    padding: 4px 9px 4px 5px;
}

#file {
    visibility: hidden;
}

@media (max-width: 1400px) {
    .lists-container {
        width: 80vw;
    }
    .lists-title {
        width: 80vw;
    }
}

@media (max-width: 900px) {
    .year-wrapper {
        width: 94%;
    }
    #schedule-lists-container {
        max-height: 416px;
    }
}

@media (max-width: 660px) {
    .lists-container {
        max-height: 208px;
    }
}

@media (max-width: 520px) {
    .lists-title {
        width: 91vw;
        font-size: 20px;
    }
    .lists-container {
        padding: 4px;
        width: 91vw;
        margin-bottom: 16px;
    }
    .year-wrapper {
        margin: 12px 8px;
    }
    .schedule-button {
        font-size: 16px;
    }
    #delete-schedule {
        top: 23px;
        right: 86px;
    }
    #copy-link-schedule {
        top: 23px;
        right: 54px;
    }
    #menu-reference {
        top: 23px;
        right: 26px;
    }
    .menu-container {
        top: 2px;
        right: -10px;
        width: 200px;
    }
    .menu-row {
        font-size: 14px;
        height: 36px;
    }
    .menu-icon {
        font-size: 16px;
    }
    #export-icon {
        font-size: 18px;
    }
    #import-icon {
        font-size: 18px;
    }
}