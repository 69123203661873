/* Course Items */

.draggable-item {
    margin: 4px;
}

.course-item {
    background: #cccccc;
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
    text-align: center;
    padding: 4px;
    font-family: Calibri,sans-serif;
    font-size: 17.5;
    overflow-wrap: break-word;
}

.course-item:hover {
    cursor: grab;
    filter: brightness(0.92);
}

.course-item:active {
    cursor: grabbing;
    transform: scale(0.98);
}

.course-item.lowerDivList {
    background-color: #cefed0;
    background-image: linear-gradient(to bottom, #cefed0, #e7ffe8);
}

.course-item.upperDivList {
    background-color: #ceeffe;
    background-image: linear-gradient(to bottom, #c8eeff, #e4f7ff);
}

.course-item.breadthList {
    background-color: #fef8a8;
    background-image: linear-gradient(to bottom, #fef8a8, #fffcce);
}

.course-item.minorList {
    background-color: rgba(255,194,255,.7);
    background-image: linear-gradient(to bottom, rgb(250, 198, 255), rgb(252, 226, 255));
}

.course-item.custom {
    background-color: #ffdfa6;
    background-image: linear-gradient(to bottom, #ffdfa6, #ffedcd);
}

/* Event Modifiers */

.dragging {
    opacity: 0.6;
    cursor: grabbing;
}

.clicked {
    filter: brightness(0.88);
}